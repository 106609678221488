<template>
  <div 
    class="chart"
    :id="chartId">
  </div>
</template>

<script>
var echarts = require('echarts');
import china from '@/websites/cms/js/china.json'
echarts.registerMap('china', china);
export default {
  props: {
    chartId: { // 图表ID
      type: String,
      default(){
        return this.$RandomCode();
      },
    },
    option: { // 图表数据
      type: Object,
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      myChart: null
    };
  },
  watch: {
    option: {
      handler(){
        this.$nextTick(e => this.init());
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    // 如果存在图表，则清空内容
    if (this.myChart === null && this.myChart !== "" && !(typeof this.myChart === "undefined")) this.pie.clear();
  },
  methods: {
    init(){
      if (this.myChart === null && this.myChart !== "" && !(typeof this.myChart === "undefined")) {
        this.myChart = echarts.init(document.getElementById(this.chartId));
      }
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(this.option);
    }
  },
};
</script>

<style lang="scss" scoped>
.chart{
  width: 100%;
  height: 100%;
}
</style>