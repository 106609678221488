<template>
  <div class="container">
    <!-- 查询条件 -->
    <analysis-query @ready="batchParams"></analysis-query>

    <!-- 任务完成情况 -->
    <div class="chart-bar">
      <p v-if="!chartBarStatus" class="chart-bar-none">暂无数据</p>
      <div v-else id="chart-bar" class="chart-bar-canvas"></div>
    </div>

    <div class="chart-item">
      <!-- 地域分布图 -->
      <div class="chart-map">
        <map-chart chartId="chart1" :option="options"></map-chart>
      </div>

      <!-- 线索访问趋势图 -->
      <div class="chart-map">
        <div id="chart-line" class="chart-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MapChart from '@/websites/cms/components/charts/Map'
import AnalysisQuery from '@/websites/cms/components/AnalysisQuery'
export default {
  components: {
    'map-chart': MapChart,
    'analysis-query': AnalysisQuery
  },
  data(){
    return {
      chartBarStatus: true,
      params: {
        teamId: '',
        sourceType: 'card',
        times: [],
        memberName: ''
      },
      sourceList: [{name: '名片', val: 'card'}],
      region: {
        "Beijing": "北京",
        "Tianjin": "天津",
        "Hebei": "河北",
        "Shanxi": "山西",
        "Inner Mongolia Autonomous Region": "内蒙古",
        "Liaoning": "辽宁",
        "Jilin": "吉林",
        "Heilongjiang": "黑龙江",
        "Shanghai": "上海",
        "Jiangsu": "江苏",
        "Zhejiang": "浙江",
        "Anhui": "安徽",
        "Fujian": "福建",
        "Jiangxi": "江西",
        "Shandong": "山东",
        "Henan": "河南",
        "Hubei": "湖北",
        "Hunan": "湖南",
        "Guangdong": "广东",
        "Guangxi": "广西",
        "Hainan": "海南",
        "Chongqing": "重庆",
        "Sichuan": "四川",
        "Guizhou": "贵州",
        "Yunnan": "云南",
        "Tibet": "西藏",
        "Shaanxi": "陕西",
        "Gansu": "甘肃",
        "Qinghai": "青海",
        "Ningxia": "宁夏",
        "Xinjiang": "新疆",
        "Taiwan": "台湾",
        "Xianggang": "香港",
        "Aomen": "澳门",
        "Diaoyudao": "钓鱼岛"
      },
      options: {
        title: {text: '线索地域分布', left: 'center'},
        tooltip: {trigger: 'item'},
        geo: {
          map: 'china',
          show: true,
          roam: false,
          label: {emphasis: {show: false}},
          itemStyle: {
            normal: {
              borderColor: 'rgba(0,63,140,0.2)',
              shadowColor: 'rgba(0,63,140,0.2)',
              shadowOffsetY: 20,
              shadowBlur: 30
            }
          }
        },
        visualMap: {
          type: 'piecewise',
          left: '15',
          bottom: '15',
          itemWidth: 27,
          itemHeight: 15,
          textStyle: {
            color: '#333333',
            fontSize: 14,
          },
          pieces: [{
            min: 500,
            label: '>500',
          }, {
            max: 500,
            min: 200,
            label: '200-500',
          }, {
            max: 200,
            min: 0,
            label: '<200',
          }, {
            value: 0,
            label: '无数据',
          }, ],
          inRange: {
            color: ['#F2F2F2', '#D2EAFF', '#8AC6FD', '#45A5F8']
          },
          outOfRange: {
            color: ['#999999']
          }
        },

        // 下载按钮
        toolbox: {
          show: true,
          orient : 'vertical',
          left: 'right',
          top: 'left',
          feature : {
            saveAsImage : {show: true}
          }
        },

        // 数据源
        series : [{
          name: '线索',
          type: 'map',
          map: 'china',
          aspectScale: 0.75,
          zoom:1.2,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#B2CAE0',
              borderColor: '#fff',
              borderWidth: 1,
            },
            emphasis: {
              areaColor: '#FFAE00',
            }
          },
          label: {
            normal: {
              show: true
            },
            emphasis: {
              show: true
            }
          },
          data:[]
        }]
      }
    }
  },
  methods: {
    // 组装查询条件
    batchParams(data){
      let params = {rt: data.sourceType, cid: data.companyId};
      if(data.companyId != data.teamId) params.tid = data.teamId;
      params.ranges = [{from: data.times[0], to: this.$dayjs(data.times[1]).add(1, 'day').format('YYYY-MM-DD')}]
      this.getRegionList(params);
      this.getLineList(params);
      this.getBarList(params);
    },

    // 获取按销售人员查询到的柱状图
    async getBarList(params){
      this.chartBarStatus = true;
      let res = await this.$ZGManager.statSellerSpread({...params, evt: 'visit'});
      let option = {
        title: {text: '线索访问明细', left: 'center'},
        tooltip: {
          trigger: 'axis',
          axisPointer: {type: 'shadow'}
        },
        grid: {left: '4%', right: '4%'},
        xAxis: {type: 'category'},
        yAxis: {type: 'value'},
        series: [{
          name: '线索',
          type: 'bar',
          barWidth: '10%',
          itemStyle: {normal: {color: '#1a9afd'},},
          data: []
        },
        {
          name: '访问',
          type: 'bar',
          barWidth: '10%',
          itemStyle: {normal: {color: 'rgba(16, 154, 253, 0.4)'}},
          data: []
        }]
      };
      if(res.statu != 200 && res.data.length < 1){
        this.chartBarStatus = false;
      }else{
        let dataa = [];
        let datab = [];
        res.data.map((e) => {
          dataa.push([e.name || '佚名', e.uv]);
          datab.push([e.name || '佚名', e.pv]);
        });
        let endValue = res.data[res.data.length - 1].name || '匿名';
        if(res.data.length > 20) endValue = res.data[20].name || '匿名';
        option.series[0].data = dataa;
        option.series[1].data = datab;
        option.dataZoom = [{
          xAxisIndex: [0],
          startValue: res.data[0].name,
          endValue: endValue
        }];
      }
      
      let myChart = this.$allecharts.init(document.getElementById('chart-bar'));
      myChart.setOption(option);
    },

    // 获取按照地域划分的线索数
    async getRegionList(params){
      var outname = ["南海诸岛", '北京', '天津', '上海', '重庆', '河北', '河南', '云南', '辽宁', '黑龙江', '湖南', '安徽', '山东', '新疆', '江苏', '浙江', '江西', '湖北', '广西', '甘肃', '山西', '内蒙古', '陕西', '吉林', '福建', '贵州', '广东', '青海', '西藏', '四川', '宁夏', '海南', '台湾', '香港', '澳门'];
      var outdata = [];
      for (var i = 0; i < outname.length; i++) {
        outdata.push({name: outname[i], value: 0});
      }
      let res = await this.$ZGManager.statDistribution(params, 'user', 'geoip.region_name');
      res.data.map((e) => {
        e.name = this.region[e.key] || e.key;
        e.value = e.pv;
      });
      outdata.map((e) => {
        let index = res.data.findIndex(item => item.name == e.name);
        if(index != -1) e.value = res.data[index].value;
      });
      this.options.series[0].data = outdata;
    },

    // 获取线索和访问数
    async getLineList(params){
      let userData = [];
      let tmpUserData = {};
      let user = await this.$ZGManager.statHistograms(params, 'd', 'user');
      if(user.status == 200) user.data.histograms.map(e => tmpUserData[e.key] = e.uv);

      let visitData = [];
      let tmpVisitData = {};
      let visit = await this.$ZGManager.statHistograms({...params, evt: 'visit'}, 'd', 'trace');
      if(user.status == 200) visit.data.histograms.map(e => tmpVisitData[e.key] = e.pv);

      let xdata = [];
      let timeDiff = this.$dayjs(params.ranges[0].to).diff(this.$dayjs(params.ranges[0].from), 'day');
      for(let i = 0; i < timeDiff; i++){
        let a = this.$dayjs(params.ranges[0].from).add(i, 'day').format('YYYY-MM-DD');
        userData.push([a, tmpUserData[a] || 0]);
        visitData.push([a, tmpVisitData[a] || 0]);
      }
      
      let option = {
        title: {text: '线索访问趋势图', left: 'center'},
        tooltip: {trigger: 'axis'},
        legend: {bottom: 0, data: ['线索', '访问']},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '30',
          containLabel: true
        },
        toolbox: {feature: {saveAsImage: {}}},
        xAxis: {
          type: 'category',
          boundaryGap: false, //坐标轴两边留白
          // data: xdata,
          axisLabel: { //坐标轴刻度标签的相关设置。
            textStyle: {
              color: '#1B253A',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            },
          },
          axisTick: { //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: { //坐标轴轴线相关设置
            lineStyle: {
              color: '#E5E9ED',
            }
          },
          splitLine: { //坐标轴在 grid 区域中的分隔线。
            show: true,
            lineStyle: {
              color: '#E5E9ED',
            }
          }
        },
        yAxis: [{
          type: 'value',
          splitNumber: 5,
          axisLabel: {
            textStyle: {
              color: '#a8aab0',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E5E9ED',
            }
          }
        }],
        series: [
          {
            name: '线索',
            type: 'line',
            itemStyle: {
                normal: {
                    color: '#3A84FF',
                    lineStyle: {
                        color: "#3A84FF",
                        width: 1
                    },
                    areaStyle: {
                        color: new this.$allecharts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: 'rgba(58,132,255,0)'
                        }, {
                            offset: 1,
                            color: 'rgba(58,132,255,0.35)'
                        }]),
                    }
                }
            },
            data: userData
          },
          {
            name: '访问',
            type: 'line',
            itemStyle: {
                normal: {
                    color: 'rgba(255,80,124,1)',
                    lineStyle: {
                        color: "rgba(255,80,124,1)",
                        width: 1
                    },
                    areaStyle: {
                        color: new this.$allecharts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: 'rgba(255,80,124,0)'
                        }, {
                            offset: 1,
                            color: 'rgba(255,80,124,0.35)'
                        }]),
                    }
                }
            },
            data: visitData
          }
        ]
      };
      let myChart = this.$allecharts.init(document.getElementById('chart-line'));
      myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-bar{
  width: calc(100% - 60px);
  height: 500px;
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .chart-bar-canvas{
    width: 100%;
    height: 100%;
  }
  .chart-bar-none{
    color: #999;
    text-align: center;
    line-height: 500px;
  }
}
.chart-item{
  display: flex;
  justify-content: space-between;
  .chart-map{
    width: calc(50% - 67.5px);
    height: 400px;
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    .chart-line{
      width: 100%;
      height: 100%;
    }
  }
}
</style>